<template>
  <div>
    <validation-observer
      ref="obs"
      v-slot="{ handleSubmit }"
    >
      <form>
        <v-row
          class="mt-5"
          align="center"
          dense
        >
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors, valid }"
              :name="$t('Object')"
              rules="required"
            >
              <v-text-field
                v-model="submitForm.object"
                :label="$t('Object')"
                :error-messages="errors"
                :success="valid"
                required
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row
          class="mt-5"
          align="center"
          dense
        >
          <v-col cols="12">
            <span class="text-right body-1 grey--text">Message</span>
            <tiptap-vuetify
              v-model="submitForm.body"
              :extensions="tiptapExtensions"
            />
            <span class="red--text">{{ errorsMessage }}</span>
          </v-col>
        </v-row>
        <!--  Bouton submit-->
        <v-btn
          color="success"
          @click="handleSubmit(submit)"
        >
          {{ $t('Validate') }}
        </v-btn>
      </form>
    </validation-observer>

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t('tools.Sent_message_customers') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <validation-observer
          v-slot="{ handleSubmit }"
        >
          <form>
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('Object') }}
              </v-col>
              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Object')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.object"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row
              class="mt-5"
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                Message
              </v-col>
              <v-col cols="8">
                <div v-html="submitForm.body" />
              </v-col>
            </v-row>
            <!--  Bouton submit-->
            <div class="pa-3 text-center">
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="dialog = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(sendEmail)"
              >
                {{ $t('order.operation.Send_email') }}
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  // Service
  import MailerService from '@/services/MailerService'
  // Other
  import { TiptapVuetify, Heading, Paragraph, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Image } from 'tiptap-vuetify'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'ToolsSendEmail',
    components: {
      TiptapVuetify,
      ValidationObserver,
      ValidationProvider,
    },
    data () {
      return {
        submitForm: {
          object: null,
          body: null,
        },
        dialog: false,
        errorsMessage: null,
        tiptapExtensions: [
          History,
          Blockquote,
          Link,
          Image,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [Heading, {
            options: {
              levels: [1, 2, 3],
            },
          }],
          Bold,
          Paragraph,
          HorizontalRule,
          HardBreak,
        ],
      }
    },

    computed: {
    },

    watch: {
      'submitForm.body' (val) {
        if (val) if (val.replace(/<[^>]*>?/gm, '')) this.errorsMessage = ''
      },
    },

    methods: {
      submit () {
        if (!this.submitForm.body) {
          this.errorsMessage = this.$i18n.t('messages.Message_required')
          return
        }
        if (!this.submitForm.body.replace(/<[^>]*>?/gm, '')) {
          this.errorsMessage = this.$i18n.t('messages.Message_required')
          return
        }

        this.dialog = true
      },
      sendEmail () {
        try {
          // Start Spinner
          this.$loading.show()
          MailerService.sendEmailToCustomer(this.submitForm)
          this.dialog = false
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
