import axios from 'axios'
import { msalInstance, loginRequest } from '@/config/msal'

export const files = {
    methods: {
        async downloadFileFromS3 (key, bucket) {
            try {
                const params = {
                  bucket: bucket,
                  key: key,
                  expires: 60,
                }

                // Récupération sur le client
                const accounts = msalInstance.getAllAccounts()
                // Récupération du token
                const responseToken = await msalInstance.acquireTokenSilent({
                  scopes: loginRequest.scopes,
                  accounts,
                })

                // On regarde si l'utilisateur à des photos
                const response = await axios.post(`${process.env.VUE_APP_SERVER_ADDRESS}${process.env.VUE_APP_SERVER_ADDRESS_PATH}/aws-s3/getSignedUrl`, params, {
                  headers: {
                    Authorization: `Bearer ${responseToken.accessToken}`,
                  },
                })

                const awsS3Response = await axios.get(response.data, { responseType: 'blob' })
                const blob = new Blob([awsS3Response.data])
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = key
                link.click()
                URL.revokeObjectURL(link.href)
            } catch (error) {
                console.log(error)
            }
        },
    },
}
