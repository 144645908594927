<template>
  <v-container
    id="operation-tools"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-hammer-screwdriver"
      :title="`${$t('Tool').toUpperCase()}S`"
      class="px-5 py-3"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-expansion-panels
          v-model="openedPanel"
          multiple
          accordion
        >
          <!--  Transférer les nouveaux fichiers e factures sur le serveur 01cell-->
          <v-expansion-panel
            value="0"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-upload
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.transfert_files').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-transfert-files @update-billing-management="updateBillingManagement" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Gestion de la facturation-->
          <v-expansion-panel
            value="1"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-logout
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Billing_management').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-billing-management
                v-if="ifBillingManagementIsOpen"
                ref="billingManagement"
                @update-billing-error="updateBillingError"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Paiements en erreur-->
          <v-expansion-panel
            value="2"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="error"
                  x-large
                >
                  mdi-alert-octagon
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Payment_in_error').toUpperCase() }}
                <!-- Îcone pour facture en erreur-->
                <v-badge
                  v-if="billingError.length"
                  color="red"
                  :content="billingError.length"
                  offset-x="25"
                  offset-y="15"
                >
                  <v-icon
                    class="ml-1"
                    left
                    dark
                    large
                    color="yellow accent-3"
                  >
                    mdi-alert
                  </v-icon>
                </v-badge>
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-billing-management-error
                ref="billingManagementError"
                :billing-error="billingError"
                @update-billing-error="updateBillingError"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Informer les clients mauvais payeurs-->
          <!-- <v-expansion-panel
            value="3"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="warning"
                  x-large
                >
                  mdi-email
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Inform_bad_paying_customers').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <!--  Télécharger dans un fichier les données d'un table-->
          <!-- <v-expansion-panel
            value="4"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-cloud-download
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Download_the_data').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <!--  Corriger un paiement-->
          <!-- <v-expansion-panel
            value="5"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-cog-refresh-outline
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Correct_payment').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <!--  Envoyé le message-25 à la clientèle-->
          <v-expansion-panel
            value="6"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="secondary"
                  x-large
                >
                  mdi-email
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Sent_message_customers').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-send-email />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </base-material-card>
  </v-container>
</template>

<script>
  // Components
  import ToolsTransfertFiles from '@/components/Tools/ToolsTransfertFiles'
  import ToolsBillingManagement from '@/components/Tools/ToolsBillingManagement'
  import ToolsBillingManagementError from '@/components/Tools/ToolsBillingManagementError'
  import ToolsSendEmail from '@/components/Tools/ToolsSendEmail'
  // Services
  import FacturesService from '@/services/01Cell/FacturesService'

  export default {
    name: 'OperationTools',
    components: {
      ToolsTransfertFiles,
      ToolsBillingManagement,
      ToolsBillingManagementError,
      ToolsSendEmail,
    },
    data () {
      return {
        expanded: [],
        openedPanel: [],
        billingError: [],
      }
    },

    computed: {
      ifBillingManagementIsOpen () {
        return this.openedPanel.find(item => item === 1)
      },
    },

    async mounted () {
      // Start Spinner
      this.$loading.show()
      this.getBillingError()
    },

    methods: {
      updateBillingManagement () {
        // Viens du composant 'ToolsTransfertFiles'
        // Aprés le tranfert si la fenêtre pour la 'Gestion de la facturation' est ouverte, on va chercher les informations pour la facturation
        if (this.$refs.billingManagement) this.$refs.billingManagement.getSommaireFacturation()
      },
      async getBillingError () {
        try {
          const billingError = await FacturesService.paiementErreur()
          this.billingError = billingError.map(item => {
            item.clientDisplayName = `${item.Client.nom}, ${item.Client.prenom}`
            return item
          })
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      updateBillingError () {
        this.getBillingError()
      },
    },
  }
</script>

<style scoped>
  .icon {
    order: 0;
  }

  .header {
    order: 1;
  }
</style>
