var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',[_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Object'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Object'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.object),callback:function ($$v) {_vm.$set(_vm.submitForm, "object", $$v)},expression:"submitForm.object"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-right body-1 grey--text"},[_vm._v("Message")]),_c('tiptap-vuetify',{attrs:{"extensions":_vm.tiptapExtensions},model:{value:(_vm.submitForm.body),callback:function ($$v) {_vm.$set(_vm.submitForm, "body", $$v)},expression:"submitForm.body"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errorsMessage))])],1)],1),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t('Validate'))+" ")])],1)]}}])}),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.$t('tools.Sent_message_customers')))]),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" mdi-close ")])],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('Object'))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Object'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.object),callback:function ($$v) {_vm.$set(_vm.submitForm, "object", $$v)},expression:"submitForm.object"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" Message ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.submitForm.body)}})])],1),_c('div',{staticClass:"pa-3 text-center"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"success"},on:{"click":function($event){return handleSubmit(_vm.sendEmail)}}},[_vm._v(" "+_vm._s(_vm.$t('order.operation.Send_email'))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }