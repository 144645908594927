<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="sommaireFacturation"
      item-key="periode"
    >
      <!--  Lot-->
      <template
        slot="item.lot"
        slot-scope="props"
      >
        <!-- Lot-->
        <div v-if="props.item.lot">
          <v-menu>
            <template #activator="{ on, attrs }">
              <v-btn
                color="blue"
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('File') }}s
                <v-icon
                  right
                  dark
                >
                  mdi-file-excel
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in props.item.lot"
                :key="index"
                class="pointer"
                @click="getFile(item.Key)"
              >
                <v-list-item-title>{{ item.fileName }}</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer">
                <v-list-item-title @click="recreerLot(props.item.periode)">
                  [ {{ $t(`tools.billing.action_button.${props.item.action}`) }} ]
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <a
            href=""
            @click.prevent="getLotFile(props.item.lot, props.item.fournisseur, props.item.periode)"
          >{{ props.item.lot }}</a>
          <v-icon
            class="ml-1"
            dark
            color="primary"
            @click="creeLeLot(props.item.periode)"
          >
            mdi-reload
          </v-icon> -->
        </div>

        <!-- Erreur lot-->
        <div v-if="props.item.lot_erreur">
          <a
            href=""
            @click.prevent="getLotFile(props.item.lot_erreur, props.item.fournisseur, props.item.periode)"
          >{{ props.item.lot_erreur }}</a>
          <v-icon
            class="ml-1"
            dark
            color="orange"
          >
            mdi-alert
          </v-icon>
        </div>
      </template>

      <!--  Action-->
      <template
        slot="item.action"
        slot-scope="props"
      >
        <!--  Si la variable 'action' est défini, on affiche le bouton-->
        <template v-if="props.item.action">
          <!--  Si c'est un problème d'intégrité-->
          <v-badge
            v-if="props.item.action === 'contratsIntegrite'"
            bordered
            color="blue"
            :content="props.item.integrite"
            overlap
          >
            <v-btn
              small
              color="green"
              depressed
              @click="getIntegriteErreur(props.item)"
            >
              {{ $t(`tools.billing.action_button.${props.item.action}`) }}
            </v-btn>
          </v-badge>

          <!--  Si l'action est 'creerFactures'-->
          <v-btn
            v-if="props.item.action === 'creerFactures'"
            small
            color="green"
            depressed
            @click="createBills(props.item.periode)"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </v-btn>

          <!--  Si l'action est 'ActiverFactures'-->
          <v-btn
            v-if="props.item.action.startsWith('ActiverFactures')"
            small
            color="green"
            depressed
            @click="paybills"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </v-btn>

          <!--  Si l'action est 'informerClient'-->
          <v-btn
            v-if="props.item.action === 'informerClient'"
            small
            color="green"
            depressed
            @click="informerClient"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </v-btn>

          <!--  Si l'action est 'Créer le lot'-->
          <v-btn
            v-if="props.item.action === 'creerLot'"
            small
            color="green"
            depressed
            @click="creeLeLot(props.item.periode)"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </v-btn>
        </template>
        <v-icon
          v-else
          color="success"
        >
          mdi-check-circle
        </v-icon>
      </template>
    </v-data-table>

    <!--  Contrats avec les problémes d'intégrité-->
    <template v-if="integriteErreur">
      <h2>{{ $t('tools.billing.Please_complete_the_following_contracts') }}</h2>
      <contract
        :client-contrats="integriteErreurContrats"
        @update-contrats-integrite="updateContratsIntegrite"
      />
    </template>

    <!--  Loading-->
    <v-overlay
      :absolute="absolute"
      :value="overlay"
    >
      <v-progress-circular
        :width="5"
        color="purple"
        indeterminate
      />
    </v-overlay>
  </div>
</template>
<script>
  // Components
  import Contract from '@/components/Client/Contract'
  // Services
  import FacturationsService from '@/services/01Cell/FacturationsService'
  // import S3 from '@/services/S3'
  // Other
  import Swal from 'sweetalert2'
  // Mixins
  import { files } from '@/mixins/files'

  export default {
    name: 'ToolsBillingManagementComponent',
    components: {
      Contract,
    },
    mixins: [files],
    data () {
      return {
        sommaireFacturation: [],
        overlay: false,
        absolute: true,
        integriteErreur: false,
        integriteErreurContrats: [],
        tableHeaders: [
          { text: this.$i18n.t('tools.billing.Period'), value: 'periode', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.Provider'), value: 'fournisseur', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Quantity'), value: 'qt', sortable: true, class: 'text-h4 primary--text' },
          { text: `$ ${this.$i18n.t('contract.bill.Total_before_tax')}`, value: 'montant_at', sortable: true, class: 'text-h4 primary--text' },
          { text: '$ TPS', value: 'tps', sortable: true, class: 'text-h4 primary--text' },
          { text: '$ TVQ', value: 'tvq', sortable: true, class: 'text-h4 primary--text' },
          { text: '$ Total', value: 'montant_total', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Lot', value: 'lot', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Action', value: 'action', sortable: false, align: 'center', class: 'text-h4 primary--text' },
        ],
      }
    },

    computed: {
    },

    mounted () {
      this.getSommaireFacturation()
    },

    methods: {
      async getFile (key) {
        this.downloadFileFromS3(key, 'cell-facturation')
      },
      async getSommaireFacturation () {
        // Start Spinner
        this.overlay = true
        try {
          this.sommaireFacturation = await FacturationsService.sommaire()
          // console.log(this.sommaireFacturation)
          // Stop Spinner
          this.overlay = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async getIntegriteErreur (value) {
        try {
          this.integriteErreurContrats = await FacturationsService.integrite()
          // On ouvre les contrats qui ont un problème d'intégrité
          this.integriteErreur = true
        } catch (error) {
          console.log(error)
        }
      },
      async createBills (periode) {
        // Start Spinner
        this.overlay = true
        try {
          await FacturationsService.creerfactures(periode)
          this.getSommaireFacturation()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async paybills () {
        // Start Spinner
        this.overlay = true
        try {
          await FacturationsService.payerFactures()
          this.getSommaireFacturation()
          // On update le erreur de facturation
          this.$emit('update-billing-error')
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async creeLeLot (periode) {
        // Start Spinner
        this.overlay = true
        try {
          await FacturationsService.creerlot(periode)
          this.getSommaireFacturation()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      recreerLot (periode) {
        Swal.fire({
          title: this.$i18n.t('Are_you_sure'),
          text: `${this.$i18n.t('tools.billing.action_button.recreerLot')} ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('Confirm'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.overlay = true
            try {
              await FacturationsService.creerlot(periode)
              this.getSommaireFacturation()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.overlay = false
            }
          }
        })
      },
      getLotFile (key, provider, period) {
        this.downloadFileFromS3(`fournisseur/${provider}/${period}/${key}`, 'cell-facturation')
        // try {
        //   const params = {
        //     bucket: 'cell-facturation',
        //     key: `fournisseur/${provider}/${period}/${key}`,
        //     expires: 60,
        //   }

        //   const getSignedUrl = await S3.getSignedUrl(params)
        //   window.open(getSignedUrl, '_blank')
        // } catch (error) {
        //   console.log(error)
        // }
      },
      async informerClient () {
        // Start Spinner
        this.overlay = true
        try {
          await FacturationsService.informerClient()
          this.getSommaireFacturation()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async updateContratsIntegrite () {
        // Start Spinner
        this.overlay = true
        // On ferme le composant 'Contract' pour faire un rafraichissement pour les contrats
        this.integriteErreur = false
        try {
          const sommaireFacturationPromise = FacturationsService.sommaire()
          const integriteErreurContratsPromise = FacturationsService.integrite()
          const [sommaireFacturation, integriteErreurContrats] = await Promise.all([sommaireFacturationPromise, integriteErreurContratsPromise])
          this.sommaireFacturation = sommaireFacturation

          if (integriteErreurContrats.length) {
            // On remets les problèmes d'intégrité
            this.integriteErreurContrats = integriteErreurContrats

            // On ouvre le composant 'Contrat'
            this.integriteErreur = true
          }

          // Stop Spinner
          this.overlay = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
    },
  }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
